import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/SEO"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Text from "../components/Text"
import Peragraphs from "../components/Peragraphs"



const ServicesPage = ({ data }) => {

  // console.log(data);

  var node = {};
  var paragraphs = {};
  node = data.drupal.pageNode;
  let imageUrl = node.fieldImage !== null && node.fieldImage.entity !== null ? node.fieldImage.entity.thumbnail.url : "";
  if(data.drupal.pageNode.fieldContent && data.drupal.pageNode.fieldContent !== null){
    paragraphs = node.fieldContent;
  }
  
  // console.log(paragraphs);
  return (
      <div className={ node.language.id === "en" ? "EN" :"UR"}>
        <Seo post={{
          title: node.entityLabel,
          description: node.body !== null ? node.body.value : "",
          image: imageUrl
        }} />
        <Header lang={ node.language.id === "en" ? "EN" :"UR"} />
        <div className="container-fluid bg-brand text-white mb-16">
          <div className="bg-cover bg-center"  style={{ 
                backgroundImage: `url(${imageUrl})` 
              }}>
            <div className="container-fluid mx-auto bg-black bg-opacity-60">
              <div className="narrow py-24 text-white">
                <h1 className="text-7xl font-bold font-sans">{ node.title}</h1>
                <div className="mt-12 px-2">
                  
                  <Text text={ node.body !== null ? node.body.value : ""} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Peragraphs elements={paragraphs} />
        <Footer lang={ node.language.id === "en" ? "EN" :"UR"} />
      </div>
      
  )
}

export const query = graphql`
  query($id : String!, $language: DRUPAL_LanguageId!){
    drupal {
      pageNode: nodeById(id: $id, language: $language) {
        entityId
        entityLabel
        entityType
        entityUrl {
          path
        }
        
        language: entityLanguage {
          id
        }
        nid
        title
        sticky
        ... on DRUPAL_NodeServices {
          nid
          vid
          uuid
          title
          status
          changed
          created
          entityBundle
          entityLanguage {
            id
          }
          body {
            format
            value
          }
          fieldImage: fieldServiceFeatureImage {
            entity {
              thumbnail {
                title
                url
              }
            }
          }
          entityId
          entityLabel
          fieldContent: fieldServicePageContent {
            entity {
              entityId
              entityLabel
              entityBundle
              status
              ... on DRUPAL_ParagraphUiHero {
                id
                entityId
                entityLabel
                entityType
                type {
                  targetId
                  entity {
                    ... on DRUPAL_TaxonomyTermHeroTypes {
                      tid
                      uuid
                      entityId
                      entityType
                      name
                    }
                  }
                }
                fieldUiHeroTitle {
                  entity {
                    entityId
                    entityLabel
                    entityBundle
                    ... on DRUPAL_ParagraphTitle {
                      id
                      fieldTitleAndSummary {
                        processed
                        summaryProcessed
                      }
                    }
                  }
                }
                fieldUiHeroTextSettings {
                  entity {
                    entityId
                    entityBundle
                    entityType
                    ... on DRUPAL_ParagraphTextStyling {
                      id
                      fieldHeadingColour {
                        entity {
                          entityId
                          entityLabel
                          entityBundle
                          entityType
                          ... on DRUPAL_TaxonomyTermColours {
                            tid
                            uuid
                            name
                          }
                        }
                      }
                      fieldParagraphColour {
                        entity {
                          entityId
                          entityLabel
                          entityBundle
                          ... on DRUPAL_TaxonomyTermColours {
                            tid
                            uuid
                            name
                          }
                        }
                      }
                    }
                  }
                }
                fieldUiHeroDisplayTypes {
                  entity {
                    entityId
                    entityLabel
                    entityType
                    name
                    status
                    ... on DRUPAL_TaxonomyTermHeroTypes {
                      tid
                      uuid
                      name
                    }
                    entityBundle
                  }
                }
                fieldUiHeroBackgroundSetting {
                  targetId
                  targetRevisionId
                  entity {
                    entityId
                    entityLabel
                    entityType
                    entityBundle
                    ... on DRUPAL_ParagraphBackgroundSettings {
                      id
                      entityBundle
                      entityId
                      entityLabel
                      entityType
                      fieldBackgroundColour {
                        entity {
                          entityId
                          entityLabel
                        }
                      }
                      fieldBackgroundImage {
                        width
                        url
                        title
                        height
                        alt
                      }
                      fieldUiHeroBackgroundOverlay {
                        entity {
                          entityId
                          entityLabel
                          ... on DRUPAL_ParagraphBackgroundOverlay {
                            id
                            entityBundle
                            entityId
                            entityLabel
                            entityType
                            fieldBackgroundOverlayColour {
                              entity {
                                ... on DRUPAL_TaxonomyTermColours {
                                  tid
                                  uuid
                                  entityId
                                  entityLabel
                                  name
                                  entityBundle
                                }
                              }
                            }
                            fieldBackgroundOverlayOpacity {
                              entity {
                                entityId
                                entityBundle
                                entityLabel
                                entityType
                                ... on DRUPAL_TaxonomyTermOpacityLevels {
                                  tid
                                  uuid
                                  entityId
                                  entityBundle
                                  entityLabel
                                  entityType
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                fieldImageSelector {
                  entity {
                    entityId
                    entityLabel
                    entityType
                    name
                    thumbnail {
                      alt
                      title
                      url
                      width
                      height
                    }
                    entityBundle
                  }
                }
                entityBundle
                fieldButtonsGroup {
                  entity {
                    entityId
                    entityLabel
                    entityType
                    status
                    entityBundle
                    ... on DRUPAL_ParagraphButton {
                      id
                      entityId
                      entityLabel
                      fieldButt {
                        title
                        uri
                        url {
                          path
                        }
                      }
                      fieldButtonTitle {
                        value
                      }
                      fieldButtonType {
                        entity {
                          entityId
                          entityType
                          ... on DRUPAL_TaxonomyTermButtonTypes {
                            tid
                            uuid
                            entityId
                            entityLabel
                            name
                            entityBundle
                          }
                          entityBundle
                        }
                      }
                      entityBundle
                    }
                  }
                }
              }
              ... on DRUPAL_ParagraphUiListingSelectedNodes {
                id
                entityId
                entityLabel
                entityBundle
                fieldFieldShowAuthorUnderCo
                fieldShowTypeOnContent
                fieldStaticListingType {
                  entity {
                    name
                    entityId
                    entityLabel
                    entityBundle
                  }
                }
                fieldSelectedNodeButtonGroup {
                  entity {
                    entityId
                    entityLabel
                    entityType
                    status
                    entityBundle
                    ... on DRUPAL_ParagraphButtonsGroup {
                      id
                      entityId
                      entityLabel
                      entityBundle
                      fieldButtons {
                        entity {
                          entityId
                          entityLabel
                          entityBundle
                          ... on DRUPAL_ParagraphButton {
                            id
                            entityId
                            entityBundle
                            entityLabel
                            fieldButt {
                              url {
                                path
                              }
                            }
                            fieldButtonTitle {
                              value
                            }
                            fieldButtonType {
                              entity {
                                entityId
                                entityLabel
                                entityBundle
                                ... on DRUPAL_TaxonomyTermButtonTypes {
                                  tid
                                  uuid
                                  entityId
                                  entityLabel
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                fieldUiListingSelectdTextSe {
                  entity {
                    entityId
                    entityLabel
                    entityBundle
                    ... on DRUPAL_ParagraphTextStyling {
                      id
                      entityBundle
                      entityId
                      entityLabel
                      fieldParagraphColour {
                        entity {
                          entityId
                          entityBundle
                          entityLabel
                        }
                      }
                      fieldHeadingColour {
                        entity {
                          entityId
                          entityLabel
                          entityBundle
                        }
                      }
                    }
                  }
                }
                fieldUiListingSelectedTitle {
                  entity {
                    entityId
                    entityLabel
                    entityBundle
                    ... on DRUPAL_ParagraphTitle {
                      id
                      entityId
                      entityLabel
                      entityBundle
                      fieldTitleAndSummary {
                        processed
                        summaryProcessed
                      }
                    }
                  }
                }
                fieldUiSeleclistitBackground {
                  entity {
                    entityId
                    entityLabel
                    entityBundle
                    ... on DRUPAL_ParagraphBackgroundSettings {
                      id
                      fieldBackgroundColour {
                        entity {
                          entityId
                          entityBundle
                          entityLabel
                          ... on DRUPAL_TaxonomyTermColours {
                            tid
                            uuid
                            entityBundle
                            entityId
                            entityLabel
                            name
                          }
                        }
                      }
                      fieldBackgroundImage {
                        title
                        alt
                        height
                        url
                        width
                      }
                      fieldUiHeroBackgroundOverlay {
                        entity {
                          entityBundle
                          entityId
                          entityLabel
                          ... on DRUPAL_ParagraphBackgroundOverlay {
                            id
                            entityBundle
                            entityId
                            entityLabel
                            fieldBackgroundOverlayColour {
                              entity {
                                entityId
                                entityBundle
                                entityLabel
                                ... on DRUPAL_TaxonomyTermColours {
                                  tid
                                  uuid
                                  entityId
                                  entityBundle
                                  entityLabel
                                  name
                                }
                              }
                            }
                            fieldBackgroundOverlayOpacity {
                              entity {
                                entityBundle
                                entityId
                                entityLabel
                                ... on DRUPAL_TaxonomyTermOpacityLevels {
                                  tid
                                  uuid
                                  entityBundle
                                  entityId
                                  entityLabel
                                  name
                                }
                              }
                            }
                          }
                          ... on DRUPAL_ParagraphBackgroundSettings {
                            id
                            entityBundle
                            entityId
                            entityLabel
                            fieldBackgroundColour {
                              entity {
                                entityId
                                entityLabel
                                entityBundle
                                ... on DRUPAL_TaxonomyTermColours {
                                  tid
                                  uuid
                                  entityBundle
                                  entityId
                                  entityLabel
                                  name
                                }
                              }
                            }
                            fieldBackgroundImage {
                              alt
                              height
                              title
                              url
                              width
                            }
                            fieldUiHeroBackgroundOverlay {
                              entity {
                                entityId
                                entityLabel
                                entityBundle
                                ... on DRUPAL_ParagraphBackgroundOverlay {
                                  id
                                  entityId
                                  entityLabel
                                  entityBundle
                                  fieldBackgroundOverlayColour {
                                    entity {
                                      entityBundle
                                      entityId
                                      entityLabel
                                      ... on DRUPAL_TaxonomyTermColours {
                                        tid
                                        uuid
                                        entityBundle
                                        entityId
                                        entityLabel
                                        name
                                      }
                                    }
                                  }
                                  fieldBackgroundOverlayOpacity {
                                    entity {
                                      entityId
                                      entityLabel
                                      entityBundle
                                      ... on DRUPAL_TaxonomyTermOpacityLevels {
                                        tid
                                        uuid
                                        entityBundle
                                        entityId
                                        entityLabel
                                        name
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                fieldUiSelectedNodesLinks {
                  entity {
                    entityId
                    entityBundle
                    entityLabel
                    ... on DRUPAL_ParagraphStaticList {
                      id
                      entityId
                      entityLabel
                      entityBundle
                      entityOwner {
                        entityId
                        entityLabel
                        entityBundle
                        name
                      }
                      status
                      type {
                        entity {
                          entityBundle
                          entityId
                          entityLabel
                          ... on DRUPAL_TaxonomyTermListingDisplayTypes {
                            tid
                            uuid
                            entityId
                            entityBundle
                            entityLabel
                            name
                          }
                        }
                      }
                      fieldStaticListNodes {
                        entity {
                          entityId
                          entityLabel
                          entityBundle
                          status
                          title
                          entityUrl {
                            path
                          }
                          ... on DRUPAL_NodeArticle {
                            nid
                            uuid
                            body {
                              summaryProcessed
                            }
                            entityId
                            entityLabel
                            entityBundle
                            fieldImage {
                              alt
                              height
                              targetId
                              title
                              url
                              width
                            }
                          }
                          ... on DRUPAL_NodeApplications {
                            nid
                            uuid
                            entityId
                            entityLabel
                            entityBundle
                            fieldApplicationLogo {
                              entity {
                                name
                                thumbnail {
                                  alt
                                  url
                                  width
                                  height
                                }
                              }
                            }
                            fieldApplicationName
                            fieldApplicationTagline {
                              value
                            }
                          }
                          ... on DRUPAL_NodeServices {
                            nid
                            uuid
                            entityBundle
                            entityId
                            entityLabel
                            fieldServiceFeatureImage {
                              entity {
                                entityId
                                entityLabel
                                entityBundle
                                thumbnail {
                                  alt
                                  height
                                  title
                                  url
                                  width
                                }
                              }
                            }
                            title
                            body {
                              summaryProcessed
                            }
                          }
                          ... on DRUPAL_NodeCompanyPages {
                            nid
                            uuid
                            entityBundle
                            entityId
                            entityLabel
                            fieldCompanyFeatureImage {
                              entity {
                                entityId
                                entityLabel
                                entityBundle
                                thumbnail {
                                  alt
                                  height
                                  title
                                  url
                                  width
                                }
                              }
                            }
                            title
                            body {
                              value
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on DRUPAL_ParagraphUiText {
                id
                entityId
                entityLabel
                entityType
                fieldUiTextContent {
                  summaryProcessed
                  processed
                }
              }
              ... on DRUPAL_ParagraphUiHubspotForm {
                id
                entityId
                entityLabel
                fieldHubspotForm {
                  formId
                  formTitle
                }
                entityRendered(mode: PREVIEW)
                fieldUiHubspotFormHeading {
                  entity {
                    entityId
                    entityLabel
                    ... on DRUPAL_ParagraphTitle {
                      id
                      entityId
                      entityLabel
                      fieldTitleAndSummary {
                        processed
                        summaryProcessed
                      }
                    }
                  }
                }
              }
              ... on DRUPAL_ParagraphImageTeaserCard {
                id
                entityId
                entityLabel
                entityBundle
                fieldTeaserAlignment {
                  entity {
                    entityId
                    entityLabel
                  }
                }
                fieldTeaserDescription {
                  format
                  value
                }
                fieldTeaserHeading
                fieldTeaserLink {
                  uri
                  title
                }
                fieldTeserImage {
                  entity {
                    thumbnail {
                      alt
                      height
                      targetId
                      title
                      url
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ServicesPage
